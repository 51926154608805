import React, { useContext } from "react";
import { GlobalDataContext } from "../context/context";
import HeroSection from "../components/Home/HeroSection";
import BlockAboutOne from "../components/About/BlockAboutOne";
import Values from "../components/About/Values";
import Directories from "../components/Home/Directories";
import BlockHomeOne from "../components/Home/BlockHomeOne";
import BlockHomeTwo from "../components/Home/BlockHomeTwo";
import CallToActionTwo from "../components/global/CallToActionTwo";
import ServicesHome from "../components/Home/ContentServicesHome";
import FormHome from "../components/Home/FormHome";
import Map from "../components/Contact/MapContent";
import Modal from "../components/Home/Modal";
import BaseLayout from "../components/global/BaseLayout";

function Home() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <BaseLayout PageName="Home">
      <div className="md:max-w-full w-full">
        <Modal />
        <HeroSection />
        <BlockHomeOne />
        <Directories />
        <BlockHomeTwo />
        <Values/>
        <BlockAboutOne />
        <CallToActionTwo
          SloganOne={rpdata?.dbSlogan?.[1].slogan}
          SloganTwo={rpdata?.dbSlogan?.[1].slogan}
          bgimg={`${rpdata?.stock?.[8]}`}
          phone={rpdata?.dbPrincipal?.phones[0].phone}
        />
        <ServicesHome/>
        <FormHome />
        <Map />
      </div>
    </BaseLayout>
  );
}

export default Home;
