import React from 'react'

function Loader() {
  return (
    <>
    <div className='w-full h-[100vh] bg-white flex justify-center items-center'>
      <div className="loader"></div>
    </div>
    </>
  )
}

export default Loader
