import React, { useState, useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import Form from "../Contact/Form";

const Modal = () => {
  const [showModal, setShowModal] = useState(false);
  const { rpdata } = useContext(GlobalDataContext);

  ///Mostrar el modal con el scroll al 5%
  const handleScroll = () => {
    if (window.scrollY > 5) {
      setShowModal(true);
    }

    return (
      <>
        {showModal ? (
          <>
            <div
              className="relative z-50"
              aria-labelledby="modal-title"
              role="dialog"
              aria-modal="true"
            >
              <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

              <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                    <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                      <div className="sm:flex sm:items-start">
                        <button
                          className="absolute top-0 right-0 bg-black px-2 rounded-full text-white"
                          id="modal-title"
                          onClick={() => setShowModal(false)}
                        >
                          <i className="fa fa-times"></i>
                        </button>
                        <div className="w-full flex justify-center item-center flex-col">
                          <div className="w-full flex justify-center bg-black">
                            <img
                              src={rpdata?.dbPrincipal?.logo}
                              className="md:w-[50%] w-[70%]"
                              alt="Not Found"
                            />
                          </div>
                          <Form />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  };
};

export default Modal;
