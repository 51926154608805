import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalDataContext } from "../../context/context";

function HeroSection() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="pic-wrapper">
        <figure
          className="pic-1"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <figure
          className="pic-2"
          style={{ background: `url("${rpdata?.stock?.[2]}")` }}
        ></figure>
        <figure
          className="pic-3"
          style={{ background: `url("${rpdata?.stock?.[3]}")` }}
        ></figure>
        <figure
          className="pic-4"
          style={{ background: `url("${rpdata?.stock?.[4]}")` }}
        ></figure>
        <div className="z-10 relative md:pt-[250px] pt-[200px] pb-[120px] text-center flex flex-col justify-center items-center">
          <h4 className="text-white  text-center md:px-60 px-10">
          {rpdata?.dbSlogan?.[1].slogan}
          </h4>
          <div className="md:w-[10%] w-full h-0.5 bg-white my-2"></div>
          <h1 className="text-white text-center md:px-60 px-10">
            {rpdata?.dbSlogan?.[0].slogan}
          </h1>
          <Link
            className="md:py-4 md:px-6 py-2 block btn-global w-52 text-center mt-8"
            to="/contact"
          >
            FREE ESTIMATE
          </Link>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
