import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import ServicesHome from "../Services/ServicesHome";

function ContentServicesHome() {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
    <div className="w-4/5 mx-auto my-12">
        <h2 className="text-center py-4">Our Services</h2>
    <div className="grid md:grid-cols-2 grid-col-1 gap-10">
        {rpdata?.dbServices?.slice(0,6).map((item, index) => {
          return (
            <ServicesHome
              key={index}
              bgImg={item.description[0].img}
              serviceName={item.name}
              serviceText={item.description[0].text.substring(0,140) + "..."}
            />
          );
        })}
      </div>
    </div>
      
    </>
  );
}

export default ContentServicesHome;
