import React, { useContext } from 'react';
import { GlobalDataContext } from "../../context/context";

function Values() {
    const { rpdata } = useContext(GlobalDataContext);
  return (
    <>
      <div className="container mx-auto md:w-full py-10 flex md:flex-row flex-col justify-center items-center">
        <div className="bg-white w-80 md:text-start text-center m-4 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out">
          <div className="">
            <img src={rpdata?.stock?.[4]} alt="Not Found" class="rounded-t w-full h-[200px] object-cover" />
          </div>
          <div className="flex justify-around w-full transform -translate-y-5 px-4">
            <div className="rounded-full shadow w-10 h-10 flex justify-center items-center bg-gray-100">
              <i className="fa fa-medal"></i>
            </div>
          </div>
          <div className="p-4">
            <h2 className="text-2xl uppercase">Mission</h2>
            <p>
                {rpdata?.dbValues?.[0].description}
            </p>
          </div>
        </div>
        <div className="bg-white md:text-start text-center w-80 m-4 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out">
          <div className="">
            <img src={rpdata?.stock?.[10]} alt="Not Found" class="rounded-t w-full h-[200px] object-cover" />
          </div>
          <div className="flex justify-around w-full transform -translate-y-5 px-4">
            <div className="rounded-full shadow w-10 h-10 flex justify-center items-center bg-gray-100">
              <i className="fa fa-medal"></i>
            </div>
          </div>
          <div className="p-4">
            <h2 className="text-2xl uppercase">Vision</h2>
            <p>
                {rpdata?.dbValues?.[1].description}
            </p>
          </div>
        </div>
        <div className="bg-white md:text-start text-center w-80 m-4 shadow-lg cursor-pointer rounded transform hover:scale-105 duration-300 ease-in-out">
          <div className="">
            <img src={rpdata?.stock?.[9]} alt="Not Found" class="rounded-t w-full h-[200px] object-cover" />
          </div>
          <div className="flex justify-around w-full transform -translate-y-5 px-4">
            <div className="rounded-full shadow w-10 h-10 flex justify-center items-center bg-gray-100">
              <i className="fa fa-medal"></i>
            </div>
          </div>
          <div className="p-4">
            <h2 className="text-2xl uppercase">Why Choose Us</h2>
            <p>
                {rpdata?.dbValues?.[2].description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Values;
